import '../node_modules/antd/dist/antd.less';
import '../public/styles/nprogress.css';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

import dynamic from 'next/dynamic';
import Router from 'next/router';
import nProgress from 'nprogress';

// Required by Antd calendar / datepicker
import 'dayjs/locale/ja';
import type { AppProps } from 'next/app';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import GlobalStyle from '../client/components/common/global/GlobalStyle';
import '../client/components/common/antd/styles.css';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// MSW API Mocking
if (process.env.NEXT_PUBLIC_MSW_MOCKING_ENABLED === 'true') {
  import('../mocks');
}

// Use router events to show progress bar
Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const App = dynamic(() => import('./App'), { ssr: false });

if (process.env.NODE_ENV === 'production') {
  const gtmContainerId = process.env.NEXT_PUBLIC_FEATURE_GTM_CONTAINER_ID || 'GTM-5MKM6V6J';
  const analytics = Analytics({
    app: 'tabist-dp',
    plugins: [
      googleTagManager({
        containerId: gtmContainerId,
      }),
    ],
  });
  analytics.page();
}

const MyApp = (props: AppProps) => {
  return (
    <>
      <GlobalStyle />
      <App {...props} />
    </>
  );
};

export default MyApp;
