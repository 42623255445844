/* istanbul ignore file */
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: Noto Sans JP, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  /* Amplify */
  :root {
    --amplify-primary-color: #1C3E86;
    --amplify-primary-tint: #1C3E86;
    --amplify-primary-shade: #102761;
    --amplify-secondary-color: #212222;
    --amplify-secondary-tint:#235C68;
    --amplify-secondary-shade: #555757;
  }
`;

export default GlobalStyle;
